import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const insightsQuery = gql`
  query insightsQuery($userId: ID!, $insightType: String, $target: String, $shuffle: Boolean) {
    insights(userId: $userId, insightType: $insightType, target: $target, shuffle: $shuffle) {
      id
      insightId
      personality
      type
      target
      message
      isAIGenerated
      userInsight {
        id
        userInsightId
        insightId
        reaction
        savedUserInsightId
        savedDate
        reaction
        userReflectionPrompts {
          id
          prompt
          type
          created
        }
        userReflections {
          id
          reflection
        }
        tags {
          name
          label
        }
      }
      targetUser {
        id
        avatar
        fullName
        privacy {
          profile {
            setting {
              value
            }
          }
        }
        profile {
          id
          relativePublicUrl
        }
      }
    }
  }
`;

export const getInsights = graphql(insightsQuery, {
  options: ({ userId, insightType, target, shuffle = false }) => ({
    variables: {
      insightType,
      shuffle,
      target,
      userId,
    },
    errorPolicy: "all",
  }),
});
